import { Component, Output, EventEmitter } from '@angular/core';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  private subscription: Subscription = Subscription.EMPTY;
  availableLangs: AvailableLangs;


  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

  constructor(private service: TranslocoService) { 
    this.availableLangs = this.service.getAvailableLangs();
  }

  get activeLang() {
    return this.service.getActiveLang();
  }

  change(lang: string) {
    // Ensure new active lang is loaded
    this.subscription.unsubscribe();
    this.subscription = this.service
      .load(lang)
      .pipe(take(1))
      .subscribe(() => {
        this.service.setActiveLang(lang);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectedLang = "fr";

  selectLang(lang) {
    this.selectedLang = lang;
    this.service.setActiveLang(lang);
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

}
