<div class="flexContainer" *ngIf="!sent">
  <form [formGroup]="emailForm" class="flexForm">
    <div class="flexFullRow">
      <h2>{{ "contact.title" | transloco }}</h2>
    </div>
    <div class="flexFullRow">
      <mat-form-field>
        <mat-label>{{ "contact.name" | transloco }}</mat-label>
        <input
          formControlName="name"
          matInput
          placeholder="{{ 'contact.name' | transloco }}"
        />
      </mat-form-field>
    </div>

    <div class="flexFullRow">
      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input formControlName="email" matInput placeholder="E-mail" />
      </mat-form-field>
    </div>

    <div class="flexFullRow">
      <mat-form-field>
        <mat-label>{{ "contact.message" | transloco }}:</mat-label>
        <textarea
          formControlName="message"
          matInput
          placeholder="{{ 'contact.placeHoldMessage' | transloco }}"
          #message
          rows="5"
          maxlength="250"
        ></textarea>
      </mat-form-field>
      <p
        [ngStyle]="{
          'background-color': message.textLength >= 255 ? '#f2edda' : '',
          textAlign: 'right',
          color: message.textLength >= 255 ? '' : '#cccccc'
        }"
      >
        {{ "contact.limitText" | transloco }}: {{ message.textLength }}/255
      </p>
    </div>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      (click)="onSubmit()"
    >
      Send
    </button>
  </form>
</div>

<div class="flexContainer" style="min-height: 300px; " *ngIf="sent">
  <div class="flexForm">
    <div class="flexFullRow" style="text-align: center;">
      <h1>{{ "contact.responseTitle" | transloco }}</h1>
    </div>
    <div class="flexFullRow">
      <h3>{{ "contact.responseText" | transloco }}</h3>
    </div>
  </div>
</div>
