import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit {

  showfooter = window.innerWidth > 600;
  constructor() { }
  
  ngOnInit(): void {
  }

}
