import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from './../material/material.module';
import { ProdutosComponent } from '../../app/core/produtos/produtos.component';
import { CoreComponent } from './core/core.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './home/home.component';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCarouselModule } from '@ngmodule/material-carousel';


@NgModule({
  declarations: [
    HomeComponent,
    ProdutosComponent,
    CoreComponent,
    ContactComponent,
    AboutComponent,
    
  ],
  imports: [
    CommonModule,
    RouterModule,    
    SharedModule,
    MaterialModule,
    FlexLayoutModule,
    TranslocoRootModule,
    FormsModule,
    ReactiveFormsModule,
    MatCarouselModule.forRoot(),

  ],
  // providers: [
  //   provide: TRANSLOCO_SCOPE, useValue: 
  // ]
})
export class CoreModule { }
