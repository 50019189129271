<app-header (toggleSideBarForMe)="drawer.toggle()"></app-header>

<mat-drawer-container autosize>
    <mat-drawer #drawer class="sidenav" mode="over">
        <app-sidebar (toggleSideBarForMe)="drawer.toggle()"></app-sidebar>
    </mat-drawer>

    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>

<app-footer *ngIf="showfooter"></app-footer>