import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
})
export class ContactComponent {
  emailForm: FormGroup = new FormGroup({
    name: new FormControl(""),
    email: new FormControl(null),
    message: new FormControl(null),
  });

  sent = false;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  onSubmit() {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    this.http
      .post(
        "https://formspree.io/f/xpzkaejn",
        {
          name: this.emailForm.value.name,
          replyto: this.emailForm.value.email,
          message: this.emailForm.value.message,
        },
        { headers: headers }
      )
      .subscribe((response) => {
        console.log(response);
        this.sent = true;
      });
  }
}
