<div class="phoneAlert">
  <div>
    <span class="onlyDesktop">
      24/7 {{ "header.callNow" | transloco }} +352 691 363 650
    </span>
    <span class="onlyMobile">
      <a href="tel:+352691363650" style="color: #ffffff; text-decoration: none">
        <mat-icon aria-hidden="false" aria-label="phone icon" class="iconPhone"
          >call</mat-icon
        >
      </a>
      +352 691 363 650
    </span>
  </div>
</div>

<div class="header_container">
  <div class="menu">
    <a [routerLink]="['']">
      <img
        src="../../../assets/img/logoAllo.svg"
        height="60px"
        alt="Logo Allo"
      />
    </a>
  </div>

  <div class="menu">
    <div class="menu">
      <mat-menu #menu="matMenu"> </mat-menu>

      <div class="onlyDesktop">
        <div class="menu">
          <button mat-menu-item [routerLink]="['']">
            {{ "header.home" | transloco }}
          </button>
          <button mat-menu-item [routerLink]="['/about']">
            {{ "header.aboutus" | transloco }}
          </button>
          <button mat-menu-item [routerLink]="['/contact']">
            {{ "header.menuContact" | transloco }}
          </button>
        </div>
      </div>

      <img
        (click)="selectLang('fr')"
        src="../../../assets/img/france.svg"
        height="20px"
        alt="lang fra"
        class="icon_lang"
      />

      <img
        (click)="selectLang('en')"
        src="../../../assets/img/united-kingdom.svg"
        height="20px"
        alt="lang eng"
        class="icon_lang"
      />

      <div class="onlyMobile">
        <button mat-icon-button (click)="toggleSideBar()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
