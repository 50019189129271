<mat-nav-list>
    <!-- <mat-divider></mat-divider> -->
    <!-- <h2 matSubheader>Acessos</h2> -->
    <a mat-list-item routerLinkActive="list-item-active" [routerLink]="['']" (click)="toggleSideBar()">
        <!-- <mat-icon>dashboard</mat-icon> -->
        {{'header.home' | transloco }}
    </a>
    <a mat-list-item routerLinkActive="list-item-active" [routerLink]="['/about']" (click)="toggleSideBar()" >
        {{'header.aboutus' | transloco }}
    </a>
    <!-- <mat-divider></mat-divider> -->
    <a mat-list-item routerLinkActive="list-item-active" [routerLink]="['/contact']" (click)="toggleSideBar()">
        {{'header.menuContact' | transloco }}
    </a>
</mat-nav-list>