<div class="container">
    <div fxLayout="row " fxLayoutAlign="center center" class="title">
        <div fxLayout="column " fxLayoutAlign="center center" class="title">
        <div>
            <img src="../../../assets/img/dublo.png" height="300px" alt="Phone link">
        </div>
            <div style="font-size: 2rem;">
                {{'aboutUs.title' | transloco }}
            </div>
            <div style="font-size: 1.3rem;">
                <p>{{'aboutUs.par1' | transloco }}</p>
                <p>{{'aboutUs.par2' | transloco }}</p>
            </div>
        </div>
    </div>
</div>