
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoreComponent } from './core/core/core.component';
import { HomeComponent } from './core/home/home.component';
import { ContactComponent } from './core/contact/contact.component';
import { AboutComponent } from './core/about/about.component';

const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    data: { title: 'FC | Not Authorization' },
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
      }
    ],
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
    data: { title: 'FC | Password recover' }
  }];

@NgModule({
      imports: [RouterModule.forRoot(routes)],
      exports: [RouterModule]
    })
export class AppRoutingModule { }
