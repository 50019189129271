import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],  
})
export class HomeComponent implements OnInit {

  slides = [];
  constructor(private service: TranslocoService) { }
  
  ngOnInit() {
    this.slides = [ {'image': 'https://gsr.dev/material2-carousel/assets/demo.png'},
                    {'image': 'https://gsr.dev/material2-carousel/assets/demo.png'},
                    {'image': 'https://gsr.dev/material2-carousel/assets/demo.png'},
                    {'image': 'https://gsr.dev/material2-carousel/assets/demo.png'},
                    {'image': 'https://gsr.dev/material2-carousel/assets/demo.png'}
                    ];


  }

}
