<mat-carousel
  timings="250ms ease-in"
  [autoplay]="true"
  interval="5000"
  color="primary"
  maxWidth="auto"
  proportion="35"
  slides="1"
  [loop]="true"
  [hideArrows]="false"
  [hideIndicators]="false"
  [useKeyboard]="true"
  [useMouseWheel]="false"
  orientation="ltr"
>
  <mat-carousel-slide
    #matCarouselSlide
    overlayColor="#ffffff"
    [hideOverlay]="false"
  >
    <div class="title_container">
      <div class="block_title">
        <div class="title1">
          {{ "home.title1" | transloco }}
        </div>
        <div class="title2">
          {{ "home.title2" | transloco }}
        </div>
      </div>

      <div class="block_button">
        <button mat-raised-button color="warn" [routerLink]="['contact']">
          {{ "home.linkEmail" | transloco }}
        </button>
      </div>
    </div>
  </mat-carousel-slide>

  <mat-carousel-slide
    #matCarouselSlide
    overlayColor="#00000040"
    [hideOverlay]="false"
  >
    <div class="title_container">
      <div class="block_title">
        <div class="title1">
          TESTE
          <!-- {{ "home.title1" | transloco }} -->
        </div>
        <div class="title2">
          titulo
          <!-- {{ "home.title2" | transloco }} -->
        </div>
      </div>

      <div class="block_button">
        <button mat-raised-button color="warn" [routerLink]="['contact']">
          {{ "home.linkEmail" | transloco }}
        </button>
      </div>
    </div>
  </mat-carousel-slide>
</mat-carousel>

<!-- <div class="barrafundoTop"></div> -->

<div class="service-container onlyMobile">
  <div>
    <div class="service_card">
      <div>
        <img
          src="../../../assets/img/eletrica.svg"
          height="80px"
          alt="Phone link"
          class="icon_service"
        />
      </div>
      <div class="service_description">
        <div class="serviceTitle">
          <strong>{{ "home.eletricTitle" | transloco }}</strong>
        </div>
        {{ "home.eletricSubtitle" | transloco }}
      </div>
    </div>

    <div class="service_card">
      <div>
        <img
          src="../../../assets/img/arcondicionado.svg"
          height="80px"
          alt="Phone link"
          class="icon_service"
        />
      </div>
      <div class="service_description">
        <div class="serviceTitle">
          <strong>{{ "home.heatingCoolingTitle" | transloco }}</strong>
        </div>
        {{ "home.heatingCoolingSubtitle" | transloco }}
      </div>
    </div>

    <div class="service_card">
      <div>
        <img
          src="../../../assets/img/agua.svg"
          height="80px"
          alt="Phone link"
          class="icon_service"
        />
      </div>
      <div class="service_description">
        <div class="serviceTitle">
          <strong>{{ "home.plumbingTitle" | transloco }}</strong>
        </div>
        {{ "home.plumbingSubtitle" | transloco }}
      </div>
    </div>

    <div class="service_card">
      <div>
        <img
          src="../../../assets/img/manutencao.svg"
          height="80px"
          alt="Geral link"
          class="icon_service"
        />
      </div>
      <div class="service_description">
        <div class="serviceTitle">
          <div class="serviceTitle">
            <b>{{ "home.generalServicesTitle" | transloco }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="service-container onlyDesktop">
  <div style="display: flex">
    <div
      class="service_description"
      style="flex: 1; text-align: right; padding: 15px"
    >
      <div class="serviceTitle">
        <strong>{{ "home.eletricTitle" | transloco }}</strong>
      </div>
      {{ "home.eletricSubtitle" | transloco }}
    </div>

    <div style="flex: 0; padding: 10px">
      <img
        src="../../../assets/img/eletrica.svg"
        height="80px"
        alt="Phone link"
        class="icon_service"
      />
    </div>

    <div style="flex: 0; padding: 10px">
      <img
        src="../../../assets/img/arcondicionado.svg"
        height="80px"
        alt="Phone link"
        class="icon_service"
      />
    </div>

    <div class="service_description" style="flex: 1; padding: 15px">
      <div class="serviceTitle">
        <strong>{{ "home.heatingCoolingTitle" | transloco }}</strong>
      </div>
      {{ "home.heatingCoolingSubtitle" | transloco }}
    </div>
  </div>

  <div style="display: flex">
    <div
      class="service_description"
      style="flex: 1; text-align: right; padding: 15px"
    >
      <div class="serviceTitle">
        <div class="serviceTitle">
          <strong>{{ "home.generalServicesTitle" | transloco }}</strong>
        </div>
      </div>
    </div>

    <div style="flex: 0; padding: 10px">
      <img
        src="../../../assets/img/manutencao.svg"
        height="80px"
        alt="Geral link"
        class="icon_service"
      />
    </div>

    <div style="flex: 0; padding: 10px">
      <img
        src="../../../assets/img/agua.svg"
        height="80px"
        alt="Phone link"
        class="icon_service"
      />
    </div>

    <div class="service_description" style="flex: 1; padding: 15px">
      <div class="serviceTitle">
        <strong>{{ "home.plumbingTitle" | transloco }}</strong>
      </div>
      {{ "home.plumbingSubtitle" | transloco }}
    </div>
  </div>
</div>

<!-- <div class="service-container">
  <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="20px">
    <div
      fxFlex.gt-xs="20"
      fxFlex.lt-sm="100"
      fxLayout.gt-xs="column"
      fxLayout.lt-sm="row wrap"
    >
      <div fxFlex="90px">
        <img
          src="../../../assets/img/eletrica.svg"
          height="80px"
          alt="Phone link"
          class="icon_service"
        />
      </div>
      <div fxFlex>
        <div class="serviceTitle">
          <b>{{ "home.eletricTitle" | transloco }}</b>
        </div>
        <b>{{ "home.eletricSubtitle" | transloco }}</b>
        <ul>
          <li>{{ "home.eletricDesc1" | transloco }}</li>
          <li>{{ "home.eletricDesc2" | transloco }}</li>
          <li>{{ "home.eletricDesc3" | transloco }}</li>
          <li>{{ "home.eletricDesc4" | transloco }}</li>
          <li>{{ "home.eletricDesc5" | transloco }}</li>
          <li>{{ "home.eletricDesc6" | transloco }}</li>
        </ul>
      </div>
    </div>

    <div
      fxFlex.gt-xs="20"
      fxFlex.lt-sm="100"
      fxLayout.gt-xs="column"
      fxLayout.lt-sm="row wrap"
    >
      <div fxFlex="90px">
        <img
          src="../../../assets/img/arcondicionado.svg"
          height="80px"
          alt="Phone link"
          class="icon_service"
        />
      </div>
      <div fxFlex>
        <div class="serviceTitle">
          <b>{{ "home.heatingCoolingTitle" | transloco }}</b>
        </div>
        <b>{{ "home.heatingCoolingSubtitle" | transloco }}</b>
        <ul>
          <li>{{ "home.heatingCoolingDesc1" | transloco }}</li>
          <li>{{ "home.heatingCoolingDesc2" | transloco }}</li>
          <li>{{ "home.heatingCoolingDesc3" | transloco }}</li>
          <li>{{ "home.heatingCoolingDesc4" | transloco }}</li>
          <li>{{ "home.heatingCoolingDesc5" | transloco }}</li>
          <li>{{ "home.heatingCoolingDesc6" | transloco }}</li>
          <li>{{ "home.heatingCoolingDesc7" | transloco }}</li>
        </ul>
      </div>
    </div>

    <div
      fxFlex.gt-xs="20"
      fxFlex.lt-sm="100"
      fxLayout.gt-xs="column"
      fxLayout.lt-sm="row wrap"
    >
      <div fxFlex="90px">
        <img
          class="menuIcon"
          src="../../../assets/img/agua.svg"
          height="80px"
          alt="Phone link"
          class="icon_service"
        />
      </div>
      <div fxFlex>
        <div class="serviceTitle">
          <b>{{ "home.plumbingTitle" | transloco }}</b>
        </div>
        <b>{{ "home.plumbingSubtitle" | transloco }}</b>
        <ul>
          <li>{{ "home.plumbingDesc1" | transloco }}</li>
          <li>{{ "home.plumbingDesc2" | transloco }}</li>
          <li>{{ "home.plumbingDesc3" | transloco }}</li>
          <li>{{ "home.plumbingDesc4" | transloco }}</li>
        </ul>
      </div>
    </div>

    <div
      fxFlex.gt-xs="20"
      fxFlex.lt-sm="100"
      fxLayout.gt-xs="column"
      fxLayout.lt-sm="row wrap"
    >
      <div fxFlex="90px">
        <img
          class="menuIcon"
          src="../../../assets/img/manutencao.svg"
          height="80px"
          alt="Geral link"
          class="icon_service"
        />
      </div>
      <div fxFlex>
        <div class="serviceTitle">
          <div class="serviceTitle">
            <b>{{ "home.generalServicesTitle" | transloco }}</b>
          </div>
          <ul>
            <li>{{ "home.generalServicesDesc1" | transloco }}</li>
            <li>{{ "home.generalServicesDesc2" | transloco }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="barrafundoBottom"></div>

<div style="max-width: 900px; margin: 0 auto">
  <a
    class="whats-app onlyDesktop"
    href="https://api.whatsapp.com/send?phone=+352691363650"
    target="_blank"
  >
    <img
      class="menuIcon"
      src="../../../assets/img/WhatsApp.svg"
      height="50px"
      alt="whatsapp link"
    />
  </a>
  <a
    class="whats-app onlyMobile"
    href="whatsapp://send?phone=+352691363650"
    target="_blank"
  >
    <img
      class="menuIcon"
      src="../../../assets/img/WhatsApp.svg"
      height="50px"
      alt="whatsapp link"
    />
  </a>
  <a
    class="whats-app onlyDesktop"
    href="https://api.whatsapp.com/send?phone=+352691363650"
    target="_blank"
  >
    <img
      class="menuIcon"
      src="../../../assets/img/WhatsApp.svg"
      height="50px"
      alt="whatsapp link"
    />
  </a>
  <a class="callnow onlyMobile" href="tel:+352691363650">
    <img
      class="menuIcon"
      src="../../../assets/img/phone.svg"
      height="40px"
      alt="Phone link"
    />
  </a>
</div>
